import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../Assets/logo.png';

// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import FlagIcon from '@mui/icons-material/Language';
// import SettingsIcon from '@mui/icons-material/Settings';

export default function MyAppBar() {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='static'>
				<Toolbar
					style={{
						height: '10vh',
						flexDirection: 'row',
						justifyContent: 'center',
						backgroundColor: '#7FB19C',
						borderBottom: '3px solid white',
					}}
				>
					{/* <IconButton
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ mr: 2 }}
					>
						<FlagIcon />
					</IconButton> */}
					<img style={{ height: '60%' }} src={Logo} alt='ZiggySearch' />
					{/* <IconButton
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ mr: 2 }}
					>
						<SettingsIcon />
					</IconButton> */}
				</Toolbar>
			</AppBar>
		</Box>
	);
}
