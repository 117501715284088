import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	CircleMarker,
} from 'react-leaflet';
import * as L from 'leaflet';
import React from 'react';
import icon from '../Assets/boy-with-cap-icon.webp';
import markerIcon from '../Assets/location-black.png';
import markerIconSelected from '../Assets/location-mint.png';
import { useEffect, useState } from 'react';
import locations from '../Assets/Locations.json';
import MarkerClusterGroup from 'react-leaflet-cluster';

const Markers = ({ setActiveMarker, selectedMarker }) => {
	let entriesArray = Object.values(locations);
	entriesArray = entriesArray.filter(
		(location) =>
			location.coordinates !== undefined &&
			location.coordinates.latitude !== undefined &&
			location.coordinates.longitude !== undefined &&
			location.coordinates !== null &&
			location.coordinates.latitude !== null &&
			location.coordinates.longitude !== null
	);

	let markers = entriesArray.map((value) => (
		<Marker
			position={[value?.coordinates.latitude, value?.coordinates.longitude]}
			eventHandlers={{
				click: () => {
					setActiveMarker(value.id);
				},
			}}
			icon={
				selectedMarker == value.id
					? L.icon({
							iconUrl: markerIconSelected,
							iconSize: [38, 50],
					  })
					: L.icon({
							iconUrl: markerIcon,
							iconSize: [38, 50],
					  })
			}
		/>
	));
	const markersArray = Object.entries(markers);
	return markersArray;
};
const askPermission = (setUserLocation, map) => {
	navigator.geolocation.getCurrentPosition(
		function (position) {
			setUserLocation({
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			});
			map.current.flyTo(
				{
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				},
				16
			);

			console.log('current location is ' + position.coords.latitude);
		},
		(error) => {
			alert(
				'Stelle sicher, dass die Standortdienste für diesen Browser in den Sicherheitseinstellungen zugelassen sind'
			);
			console.log(error);
		}
	);
};

const MyMapComponent = ({
	center,
	zoom,
	userLocation,
	setSelectedMarker,
	mapRef,
	setUserLocation,
	selectedMarker,
}) => {
	return (
		<MapContainer
			style={{ height: '90vh' }}
			// preferCanvas={true}
			center={center}
			zoom={zoom}
			maxZoom={17}
			minZoom={5}
			scrollWheelZoom={true}
			ref={mapRef}
			whenReady={() => askPermission(setUserLocation, mapRef)}
		>
			<MarkerClusterGroup chunkedLoading>
				<Markers
					selectedMarker={selectedMarker}
					setActiveMarker={setSelectedMarker}
				/>
			</MarkerClusterGroup>

			{userLocation.lat && userLocation.lng && (
				<Marker
					position={[userLocation.lat, userLocation.lng]}
					icon={L.icon({
						iconUrl: icon,
						iconSize: [50, 50],
					})}
				>
					<Popup>Dein Standort</Popup>
				</Marker>
			)}
			<TileLayer
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			/>
		</MapContainer>
	);
};
export default MyMapComponent;
