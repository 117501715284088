import IconButton from '@mui/material/IconButton';
import SpeedIcon from '@mui/icons-material/Speed';

const SpeedButton = () => {
	return (
		<div style={{ fontSize: 40 }}>
			<IconButton
				style={{
					position: 'absolute',
					zIndex: 1000,
					bottom: 50,
					right: 50,
					width: 100,
					height: 100,
					borderRadius: 50,
					border: '3px solid white',
					backgroundColor: '#7FB19C',
				}}
				size='large'
				edge='start'
				color='inherit'
				aria-label='menu'
				sx={{ mr: 2 }}
			>
				<SpeedIcon style={{ fontSize: 50, color: 'white' }} />
			</IconButton>
		</div>
	);
};
export default SpeedButton;
