import locations from '../Assets/Locations.json';
import navigateIcon from '../Assets/direction-top-right-icon.png';
import { useEffect, useState } from 'react';

const weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

function checkIsOpen(location) {
	if (location.is_machine) {
		return 'Automat';
	}
	if (location.has_machine) {
		return 'Trafik Mit Automat';
	}
	const dayOfWeekDigit = new Date().getDay();
	const timestring = location.opening_hours[dayOfWeekDigit];

	if (timestring === 'geschlossen') {
		return 'Closed';
	} else if (!timestring) {
		return 'Keine Öffnungszeiten';
	} else if (!timestring.includes('-')) {
		return 'Keine Öffnungszeiten';
	} else {
		const now = new Date();
		//Date object
		const startTime = new Date();
		startTime.setHours(timestring.split('-')[0].trim().split(':')[0]);
		startTime.setMinutes(timestring.split('-')[0].trim().split(':')[1]);
		startTime.setSeconds(0);

		const endTime = new Date();
		endTime.setHours(timestring.split('-')[1].trim().split(':')[0]);
		endTime.setMinutes(timestring.split('-')[1].trim().split(':')[1]);
		startTime.setSeconds(0);
		return now >= startTime && now <= endTime ? 'Geöffnet' : 'Geschlossen';
	}
}
const LocationBar = ({ selectedMarker }) => {
	const [isOpen, setIsOpen] = useState();
	useEffect(() => {
		if (selectedMarker) {
			setIsOpen(checkIsOpen(locations[selectedMarker]));
		}
	}, [selectedMarker]);
	return (
		<div
			style={{
				flexDirection: 'column',
				justifyContent: 'flex-start',
				position: 'absolute',
				width: '100%',
				top: '12vh',
				left: '15%',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '80%',
					height: '7vh',
					zIndex: 1000,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						boxShadow: '2px 2px 2px #aaa',
						fontSize: '15px',
						fontWeight: 'bold',
						justifyContent: 'center',
						width: '75%',
						height: '100%',
						zIndex: 1000,
						backgroundColor: 'white',
						borderBottomLeftRadius: 5,
						borderTopLeftRadius: 50,
						orderTopRightRadius: 5,
						borderBottomRightRadius: 5,
					}}
				>
					{/* {locations[selectedMarker].address.street} */}
					{isOpen}
				</div>
				<div
					onClick={() => {
						window.open(
							`https://www.google.com.sa/maps/search/${locations[selectedMarker].coordinates.latitude},${locations[selectedMarker].coordinates.longitude}`
						);
					}}
					style={{
						display: 'flex',
						boxShadow: '2px 2px 2px 2px #aaa',
						alignItems: 'center',
						justifyContent: 'center',
						width: '20%',
						height: '100%',
						zIndex: 1000,
						backgroundColor: 'white',
						borderTopRightRadius: 5,
						borderBottomRightRadius: 50,
						borderBottomLeftRadius: 5,
						borderTopLeftRadius: 5,
					}}
				>
					<img
						style={{ height: '30%' }}
						src={navigateIcon}
						alt={'Navigieren'}
					/>{' '}
				</div>
			</div>
			{!locations[selectedMarker].is_machine &&
				locations[selectedMarker].opening_hours[new Date().getDay()] && (
					<div
						style={{
							position: 'relative',
							// left: 50,
							marginTop: 5,
							display: 'flex',
							alignItems: 'center',
							boxShadow: '2px 2px 2px #aaa',
							fontSize: '15px',
							fontWeight: 'bold',
							justifyContent: 'center',
							width: '50%',
							height: '5vh',
							zIndex: 1000,
							backgroundColor: 'grey',
							borderBottomLeftRadius: 50,
							borderBottomRightRadius: 50,
						}}
					>
						{locations[selectedMarker].opening_hours[new Date().getDay()]}
					</div>
				)}
		</div>
	);
};

export default LocationBar;
