import IconButton from '@mui/material/IconButton';
import MyLocation from '@mui/icons-material/MyLocation';

const CurrLocationButton = (props) => {
	return (
		<div style={{ fontSize: 40 }}>
			<IconButton
				onClick={() => {
					if (props.userLocation) {
						props.map.current.flyTo(
							{
								lat: props.userLocation.lat,
								lng: props.userLocation.lng,
							},
							16
						);
					} else {
						navigator.geolocation.getCurrentPosition(
							function (position) {
								props.setUserLocation({
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								});
								props.map.current.flyTo(
									{
										lat: position.coords.latitude,
										lng: position.coords.longitude,
									},
									16
								);

								console.log('current location is ' + position.coords.latitude);
							},
							(error) => {
								alert(
									'Stelle sicher, dass die Standortdienste für diesen Browser in den Sicherheitseinstellungen zugelassen sind'
								);
								console.log(error);
							}
						);
					}
				}}
				style={{
					position: 'absolute',
					zIndex: 1000,
					bottom: 40,
					right: 25,
					width: 75,
					height: 75,
					borderRadius: 50,
					border: '3px solid #7FB19C',
					boxShadow: '2px 2px 2px #aaa',
					backgroundColor: '#7FB19C',
				}}
				size='large'
				edge='start'
				color='inherit'
				aria-label='menu'
				sx={{ mr: 2 }}
			>
				<MyLocation style={{ fontSize: 25, color: 'white' }} />
			</IconButton>
		</div>
	);
};
export default CurrLocationButton;
