import MyMapComponent from './Components/MyMapComponent';
import MyAppBar from './Components/AppBar';
import SpeedButton from './Components/SpeedButton';
import CurrLocationButton from './Components/CurrLocationButton';
import React, { useState } from 'react';
import LocationBar from './Components/LocationBar';

const center = { lat: 47.711589, lng: 14.596415 };

const zoom = 6;

const MapScreen = () => {
	const [userLocation, setUserLocation] = useState({});
	const [selectedMarker, setSelectedMarker] = useState();
	const mapRef = React.createRef();

	return (
		<div>
			<MyAppBar />
			{selectedMarker && <LocationBar selectedMarker={selectedMarker} />}
			{/* <SpeedButton /> */}
			<div
				style={{ position: 'absolute', bottom: 50, right: 400, zIndex: 1000 }}
			>
				{userLocation.lat}
				<br />
				{userLocation.lng}
				<br />
			</div>
			<CurrLocationButton
				map={mapRef}
				setUserLocation={setUserLocation}
				userLocation={userLocation}
			/>
			<MyMapComponent
				mapRef={mapRef}
				center={center}
				zoom={zoom}
				setSelectedMarker={setSelectedMarker}
				selectedMarker={selectedMarker}
				userLocation={userLocation}
				setUserLocation={setUserLocation}
			/>
		</div>
	);
};
export default MapScreen;
